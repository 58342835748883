import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { NgModule } from "@angular/core";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { CoreModule } from "./core/core.module";
import { SharedModule } from "./shared/shared.module";
import { AdminLayoutComponent } from "./layout/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./layout/auth-layout/auth-layout.component";
import { INIT_API_CONFIGURATION } from "./core/app-api-initializer";
import { SidebarComponent } from "./layout/components/sidebar/sidebar.component";
import { TopnavComponent } from "./layout/components/topnav/topnav.component";
@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    SidebarComponent,
    TopnavComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    // core & shared
    CoreModule.forRoot(),
    SharedModule,

    // app
    AppRoutingModule,
  ],
  providers: [INIT_API_CONFIGURATION],
  bootstrap: [AppComponent],
})
export class AppModule {}
