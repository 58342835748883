<mat-toolbar color="primary" class="fix-nav box">
  <button
    type="button"
    mat-icon-button
    class="visible-md"
    (click)="toggleSidebar()"
  >
    <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
  </button>
  <div class="nav-brand">
    Smart WiFi
  </div>
  <span class="nav-spacer"></span>
  <button class="hidden-sm" mat-icon-button [matMenuTriggerFor]="profile">
    <mat-icon>account_circle</mat-icon>
  </button>
  <mat-menu #profile="matMenu">
    <button mat-menu-item (click)="openProfile()">
      <mat-icon>person</mat-icon>
      <span>Profil korisnika</span>
    </button>
  </mat-menu>
  <button mat-icon-button (click)="onLoggedout()">
    <mat-icon>exit_to_app</mat-icon>
  </button>
</mat-toolbar>
