<app-topnav></app-topnav>
<app-sidebar></app-sidebar>
<div class="main-container">
  <router-outlet></router-outlet>
</div>
<ngx-spinner
  bdColor = "transparent"
  size = "large"
  color = "#3f51b5"
  type = "ball-clip-rotate-multiple"
  [fullScreen] = "true">
  <p style="color: #3f51b5" > Učitavanje... </p>
</ngx-spinner>
