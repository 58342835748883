import { Injectable } from "@angular/core";

import { Router } from "@angular/router";
import { AuthService } from "../servicecs/auth.service";
import { AlertService } from "src/app/shared/services/alert.service";

@Injectable()
export class AuthGuardService  {
  constructor(
    public auth: AuthService,
    public router: Router,
    public alertService: AlertService
  ) {}

  canActivate(): boolean {
    if (!this.auth.isAuthenticated()) {
      this.router.navigate(["auth"]);
      return false;
    }
    return true;
  }
}
