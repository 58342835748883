import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/core/servicecs/auth.service";
import { User } from "src/app/api/models/user";
import { MessagesService } from "src/app/core/servicecs/messages.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit {
  public showMenu: string;
  user: User;
  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.showMenu = "";
    this.user = this.authService.getCurrentUser();
  }

  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = "0";
    } else {
      this.showMenu = element;
    }
  }
  checkRoles(permission) {
    if (!this.user) return false;
    let visible = false;
    this.user.roles.forEach((role) => {
      visible = role.permissions.some((p) => p.url === permission);
    });
    return visible;
  }
}
