import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { LoaderService } from "../servicecs/loader.service";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root"
})
export class TokenInterceptor implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];

  constructor(private loaderService: LoaderService, private router: Router) {}
  removeRequest(req: HttpRequest<any>) {
    const request = this.requests.find(x => x.url === req.url);
    if (request) {
      const index = this.requests.indexOf(request);
      this.requests.splice(index, 1);
    }
    this.loaderService.isLoading.next(this.requests.length > 0);
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.requests.push(req);
    this.loaderService.isLoading.next(true);
    const token = localStorage.getItem("token");
    req = req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });

    return Observable.create(observer => {
      const subscription = next.handle(req).subscribe(
        event => {
          if (event instanceof HttpResponse) {
            this.removeRequest(req);
            observer.next(event);
          }
        },
        err => {
          if (err.status === 401) {
            this.router.navigate(["auth"]);
          }

          this.removeRequest(req);
          observer.error(err);
        },
        () => {
          this.removeRequest(req);
          observer.complete();
        }
      );
      // remove request from queue when cancelled
      return () => {
        this.removeRequest(req);
        subscription.unsubscribe();
      };
    });
  }
}
