import { Component, OnInit } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { ProfileComponent } from "src/app/shared/modals/profile/profile.component";

@Component({
  selector: "app-topnav",
  templateUrl: "./topnav.component.html",
  styleUrls: ["./topnav.component.scss"]
})
export class TopnavComponent implements OnInit {
  public pushRightClass: string;

  constructor(public router: Router, private dialog: MatDialog) {
    this.router.events.subscribe(val => {
      if (
        val instanceof NavigationEnd &&
        window.innerWidth <= 992 &&
        this.isToggled()
      ) {
        this.toggleSidebar();
      }
    });
  }

  ngOnInit() {
    this.pushRightClass = "push-right";
  }

  isToggled(): boolean {
    const dom: Element = document.querySelector("body");
    return dom.classList.contains(this.pushRightClass);
  }

  toggleSidebar() {
    const dom: any = document.querySelector("body");
    dom.classList.toggle(this.pushRightClass);
  }

  onLoggedout() {
    localStorage.clear();
    this.router.navigate(["/auth"]);
  }

  openProfile() {
    const dialogRef = this.dialog.open(ProfileComponent, {
      width: "500px",
      height: "auto",
      disableClose: false,
      autoFocus: false
    });
  }
}
