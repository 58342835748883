import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import {
  trigger,
  transition,
  style,
  animate,
  state,
} from "@angular/animations";
import { AuthService } from "src/app/core/servicecs/auth.service";

import { MatDialog } from "@angular/material/dialog";
import { AlertService } from "src/app/shared/services/alert.service";
import { LoaderService } from "src/app/core/servicecs/loader.service";
import { NgxSpinnerService } from "ngx-spinner";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
@Component({
  selector: "app-admin-layout",
  templateUrl: "./admin-layout.component.html",
  styleUrls: ["./admin-layout.component.scss"],
  animations: [
    trigger("enterAnimation", [
      state("in", style({ opacity: 1 })),
      transition(":enter", [style({ opacity: 0 }), animate(600)]),
      transition(":leave", animate(600, style({ opacity: 0 }))),
    ]),
  ],
})
export class AdminLayoutComponent implements OnInit, OnDestroy {
  isCollapsed = false;
  menu = [
    {
      url: "/admin/users",
      icon: "person",
      active: true,
      text: "Korisnici",
      childrens: [],
      roles: [],
    },
  ];
  onDestroy$: Subject<any> = new Subject();
  currentUser: any;

  constructor(
    private route: Router,
    private authService: AuthService,
    private dialog: MatDialog,
    private alertService: AlertService,
    private loaderService: LoaderService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.getUser();

    this.loaderService.isLoading
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((value) => {
        setTimeout(() => {
          if (value) {
            this.spinner.show();
          } else {
            this.spinner.hide();
          }
        }, 0);
      });
  }

  getUser() {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
  }
  logout() {
    this.authService.logout();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
